/* src/components/ContactUs.css */
.contact-us-container {
  max-width: 600px;
  color: white;
  margin: 150px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.185);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.3px);
  -webkit-backdrop-filter: blur(2.3px);
  border: 1px solid rgba(0, 0, 0, 0.56);
}

.contact-us-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-us-container form {
  display: flex;
  flex-direction: column;
}

.contact-us-container label {
  font-size: 1rem;
  margin-bottom: 8px;
  margin-top: 10px;
}

.contact-us-container input, .contact-us-container textarea {
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
  border: 1px solid #000000;
  background: rgba(255, 255, 255, 0.185);
  color: beige;

  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contact-us-container textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-us-container button {
  margin-left: 30%;
  margin-right: 30%;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-container button:hover {
  background-color: #0056b3;
}

input::placeholder {
  color: #cecece; 
}

#message::placeholder {
  color: #cecece; 
}