/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Glass effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
    border-radius: 10px; /* Rounded corners for a smooth look */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for contrast */
}

.navbar .logo {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff; /* White text for contrast */
}

.navbar nav a,
.navbar .btn-docs {
    margin: 0 10px;
    color: #fff; /* White text for contrast */
    text-decoration: none;
}

.navbar nav a:hover,
.navbar .btn-docs:hover {
    color: #ddd; /* Slight color change on hover */
}

.navbar .btn-docs {
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent button */
    padding: 8px 15px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for button */
    cursor: pointer;
    transition: background-color 0.3s;
}

.navbar .btn-docs:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Darker on hover */
}
