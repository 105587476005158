.benefits-section {
    padding: 2rem;
    background-color: none;
    text-align: center;
    position: absolute;
    bottom: 0%;
    padding-bottom: 0%;
  }
  
  .benefits-heading {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .benefits-intro {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 2rem;
  }
  
  .benefits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .benefit-item {
    flex: 1 1 250px;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .benefit-item:hover {
    transform: translateY(-5px);
    filter: drop-shadow(0px 5px 5px #ffffff9f);
  }
  
  .benefit-item h3 {
    font-size: 1.3rem;
    color: #00d9ff;
    margin-bottom: 0.5rem;
  }
  
  .benefit-item p {
    font-size: 1rem;
    color: #ffffff;
  }
  
  .privacy{
    color: #ffffffda;
    font-weight: 600;
  }

  .privacy:hover{
    color: #ffffff;
    font-weight: bold;
  }