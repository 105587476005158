/* src/App.css */
body {
  background-color: #f9f9f9;
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
}
.App {
  text-align: center;
}
