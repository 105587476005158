/* src/components/IntroSection.css */
.intro {
    padding: 50px 20px;
    text-align: center;
    position: absolute;
    padding-top: 10%;
    padding-left: 7%;
    width: 41vw;

}

.intro h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffffff;
}

.intro p {
    color: #ffffff;
    margin-bottom: 30px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.btn-primary, .btn-secondary {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.btn-primary {
    background-color: #20acc5;
    color: #fff;
    font-weight: bold;

}

.btn-secondary {
    background-color: #e0e0e0;
    color: #000000;

}
