/* ResetPassword.css */

.reset-password-container {
    max-width: 400px;
    margin: 220px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.185);
    color: beige;

}
  
  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-container label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .reset-password-container input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .reset-password-container button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-password-container button:disabled {
    background-color: #aaa;
  }
  
  .status-message {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #00d9ff; 
  }
  
  .status-message.success {
    color: #00d9ff; 
  }
  