
.privacy-policy-container{
    height: 80vh;
    color: white;
    overflow-y: scroll;
}

/* PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.6;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #ffffff;
    text-align: justify;
  }
  
  .privacy-policy-container h1 {
    color: #ffffff;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 700;
  }
  
  .privacy-policy-container h2 {
    color: #ffffffd5;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0.5rem;
  }
  
  .privacy-policy-container h3 {
    color: #ffffff;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
  
  .privacy-policy-container p {
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container li {
    margin-bottom: 0.5rem;
  }
  
  .privacy-policy-container a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .privacy-policy-container a:hover {
    color: #2980b9;
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 600px) {
    .privacy-policy-container {
      padding: 1rem;
      font-size: 0.9rem;
    }
  
    .privacy-policy-container h1 {
      font-size: 2rem;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.5rem;
    }
  
    .privacy-policy-container h3 {
      font-size: 1.2rem;
    }
  }
  
  /* Print styles */
  @media print {
    .privacy-policy-container {
      max-width: none;
      margin: 0;
      padding: 0;
    }
  
    .privacy-policy-container a {
      color: inherit;
      text-decoration: none;
    }
  
    .privacy-policy-container a:after {
      content: " (" attr(href) ")";
      font-size: 0.8em;
    }
  }