/* src/components/ReportProblem.css */
.report-problem-container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.185);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.3px);
  -webkit-backdrop-filter: blur(2.3px);
  border: 1px solid rgba(0, 0, 0, 0.56);
  color: white;

}

.report-problem-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.report-problem-container form {
  display: flex;
  flex-direction: column;
}

.report-problem-container label {
  font-size: 1rem;
  margin-bottom: 8px;
  margin-top: 10px;
}



.report-problem-container input, .report-problem-container textarea {
  padding: 10px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.185);
  color: beige;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.report-problem-container textarea {
  resize: vertical;
  min-height: 150px;
}

.report-problem-container button {
  padding: 12px 20px;
  margin-left: 30%;
  margin-right: 30%;
  background-color: #dc3545;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-problem-container button:hover {
  background-color: #c82333;
}

input::placeholder {
  color: #cecece; 
}

#message::placeholder {
  color: #cecece; /* Example color - change this to your preferred color */
}